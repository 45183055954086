.App {
  text-align: center;
}

.App-logo {
  
  height: 40vmin;
  pointer-events: none;
  position:absolute; 
  left:40%;
  right: 0%;
  bottom: 0%; 
  top:5%; 
}

#top-layer {
  z-index:2
}
#bottom-layer {
  animation: App-logo-spin infinite 10s linear;
  z-index:1
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
